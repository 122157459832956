import Logo from 'assets/reactIcons/Logo'
import { Link } from 'react-router-dom'
import { Text } from 'containers/shared/components/typography/Text'
import { Col, Row, Space, Typography } from 'antd'
import { NewPasswordForm } from 'containers/app/screens/ForgotPassword/NewPassword/component/forms/NewPasswordForm'
import { APP_USERS_PASSWORD_NEW_PATH } from 'constants/routes.constants'

export const NewPasswordComponent = () => (
  <Row justify='center'>
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <Col span={24}>
        <Row justify='center'>
          <Logo />
        </Row>
      </Col>
      <Col span={24}>
        <Space size={32}>
          <Typography.Text strong>Create new password</Typography.Text>
        </Space>
      </Col>
      <Col span={24}>
        <NewPasswordForm />
      </Col>
      <Col span={24}>
        <Row justify='center'>
          <Link to={APP_USERS_PASSWORD_NEW_PATH}>
            <Text size='s'>
              <Typography.Text type='success'>Back to Reset password page</Typography.Text>
            </Text>
          </Link>
        </Row>
      </Col>
    </Space>
  </Row>
)
