/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button, Col, Input, notification, Row, Space, Typography } from 'antd'
import { inject, observer } from 'mobx-react'
import { globalColors } from 'containers/app/data/colors'
import { UnlockOutlined } from '@ant-design/icons'
import React from 'react'
import { SubmitPasswordParams } from 'containers/shared/api/session.api'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'
import styles from './styles.module.scss'

const validatePassword = (value: string) => {
  let error
  if (!value) {
    error = 'Password is required'
  } else if (value.length < 6) {
    error = 'This password incorrect length'
  }
  return error
}

export const NewPasswordForm = inject('sessionStore')(
  observer(({ sessionStore }: { sessionStore?: any }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const resetPasswordToken = queryParams.get('resetPasswordToken')

    return (
      <Row>
        <Col span={24}>
          <Formik
            initialValues={{ password: '', passwordConfirmation: '', resetPasswordToken }}
            validate={values => {
              const errors: { passwordConfirmation?: string } = {}

              if (values.password !== values.passwordConfirmation) {
                errors.passwordConfirmation = 'Passwords do not match'
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const res = await sessionStore.submitPassword({
                  password: values.password,
                  passwordConfirmation: values.passwordConfirmation,
                  resetPasswordToken: values.resetPasswordToken,
                })
                if (res.status === 200) {
                  notification.success({
                    message: 'Password update successfully',
                  })
                  setTimeout(() => {
                    navigate(APP_USERS_SIGN_IN_PATH)
                  }, 2000)
                } else {
                  notification.error({
                    message: 'Error updating password',
                  })
                }
                setSubmitting(false)
              } catch (e) {
                notification.error({
                  message: 'Error updating password',
                })
                setSubmitting(false)
              }
            }}
          >
            {(props: FormikProps<SubmitPasswordParams>) => (
              <Form>
                <Space direction='vertical' size={24} style={{ width: '100%' }}>
                  <Field name='password' validate={validatePassword}>
                    {({ field, meta }: FieldProps) => (
                      <Row>
                        <Col span={24}>
                          <Input.Password
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            size='large'
                            disabled={props.isSubmitting}
                            placeholder='New password: at least 6 characters'
                            prefix={
                              <div className={styles.inputIconPad}>
                                <UnlockOutlined style={{ color: globalColors.purple }} />
                              </div>
                            }
                          />
                          <ErrorMessage name='password'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Field name='passwordConfirmation' validate={validatePassword}>
                    {({ field, meta }: FieldProps) => (
                      <Row>
                        <Col span={24}>
                          <Input.Password
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            size='large'
                            disabled={props.isSubmitting}
                            placeholder='Comfirm new password'
                            prefix={
                              <div className={styles.inputIconPad}>
                                <UnlockOutlined style={{ color: globalColors.purple }} />
                              </div>
                            }
                          />
                          <ErrorMessage name='passwordConfirmation'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Button size='large' block type='primary' onClick={props.submitForm} disabled={props.isSubmitting}>
                    Save
                  </Button>
                </Space>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    )
  }),
)
