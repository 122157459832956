import SignInLayout from 'containers/app/screens/SignIn/layout/SignInLayout'
import { NewPasswordComponent } from 'containers/app/screens/ForgotPassword/NewPassword/component/page/NewPasswordComponent'

const NewPassword = () => (
  <SignInLayout>
    <NewPasswordComponent />
  </SignInLayout>
)

export default NewPassword
